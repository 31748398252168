import React, { forwardRef, useEffect, useState } from "react";

// js
import "../assets/js/main.min.js";

//router
import { NavLink as Link, useHistory, useRouteMatch } from "react-router-dom";

// css
import "../assets/css/main.min.css";
import "../assets/css/custom.css";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

// component

import { useDispatch, useSelector } from "react-redux";
import { getMonthlyStatus, getProfile, getTodayStatus } from "../store/admin/action.js";
import male from "../assets/images/male.png";
import moment from "moment";
import {
  bindAccountDetails,
  getHostEarning,
  getHosthistory,
} from "../store/history/history.action.js";
import DailyIncome from "./history/DailyIncome.js";
import RoomData from "./history/RoomData.js";
import VideoCall from "./history/VideoCall.js";
import Coin from "../assets/images/coin.png";
import leftArrow from "../assets/images/leftArrow.png";
// import { getLiveRoomHistory } from "../store/liveRoom/action.js";
// import { getChatRoomHistory } from "../store/chatRoom/action.js";
import { getVideoCallHistory } from "../store/videoCall/action.js";
import { permissionError } from "../util/Alert.js";
import { Toast } from "../util/Toast.js";
import HostSettlement from "./HostSettlement.js";
import gredientImage from "../assets/images/gredientImage.png";
import gredientSecondImage from "../assets/images/gredientSecondImage.png";
import Diamond from "../assets/images/Diamond.svg";
import Copy from "../assets/images/copy.png"
import { Close } from "@material-ui/icons";
import { toast } from "react-toastify";

const Admin = () => {
  // const location = useRouteMatch();
  const dispatch = useDispatch();
  const history = useHistory();
  const admin = useSelector((state) => state.admin.seller);
  const status = useSelector((state) => state.admin.status);
  const monthlyStatus = useSelector((state) => state.admin.monthlyStatus);
  const hostHistory = useSelector((state) => state.history.hostHistory);
  const hostId = localStorage.getItem("hostId");
  const currentMonth = moment().format("YYYY-MM");
  const [selectedDate, setSelectedDate] = useState(currentMonth);
  const [type, setType] = useState(1);
  const [bankDetails, setBankDetails] = useState("")
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [values, setValues] = useState({})

  const handleAddAccountClick = () => {
    setIsModalOpen(true);
  };
  const closeModal = () => {
    setIsModalOpen(false);
  }
  useEffect(() => {
    dispatch(getProfile(hostId))
      .then((res) => {
        setBankDetails(res);
        if (res?.bankDetails) {
          setValues({
            accountName: res.bankDetails.name || '',
            paymentMethod: res.bankDetails.method || '',
            accountNumber: res.bankDetails.number || ''
          });
          dispatch(getTodayStatus(res?._id))
          dispatch(getMonthlyStatus(res?._id, selectedDate))
        }
      });
    // Other dispatch calls
    dispatch(getHosthistory(currentMonth, hostId));
    dispatch(getHostEarning(hostId, currentMonth));
    dispatch(getVideoCallHistory(hostId, currentMonth));
  }, [dispatch, currentMonth, hostId]);

  const handleDateChange = (date) => {
    const selectedDateObject = moment(date).format("YYYY-MM");
    setSelectedDate(selectedDateObject);
    dispatch(getHosthistory(selectedDateObject, hostId));
    dispatch(getHostEarning(hostId, selectedDateObject));
    dispatch(getVideoCallHistory(hostId, selectedDateObject));
    // dispatch(getChatRoomHistory(hostId, selectedDateObject));
    // dispatch(getLiveRoomHistory(hostId, selectedDateObject));
  };

  const handleBack = () => {
    window.showAndroidToast();
  };

  const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
    <button
      className="btn-gray rounded-pill px-2 py-1"
      style={{ border: "none" }}
      onClick={onClick}
      ref={ref}
    >
      {value}
    </button>
  ));

  const handleCopy = async (link) => {
    if (admin?.isActive === false) return permissionError();
    try {
      await navigator.clipboard.writeText(link); // Use await to ensure clipboard action is completed.
      Toast("success", `Copy Success.`);
    } catch (error) {
      console.error("Copy failed:", error);
      Toast("error", "Copy Failed");
    }
  };

  const handleEditDetails = () => {
    const acccountData = {
      userId: bankDetails?._id,
      values
    }
    dispatch(bindAccountDetails(acccountData))
      .then((res) => {
        if (res?.status) {
          setIsModalOpen(false)
          toast.success(res?.message)
        }
      })
  }

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value })
  }

  return (
    <>
      <div class="page-container">
        <div class="page-content">
          <div class="main-wrapper p-0">
            <div className="main-section">
              <div
                className="row p-3 mb-2"
                style={{
                  zIndex: "9",
                  position: "fixed",
                  width: "100%",
                  top: "0",
                  background: "rgb(35, 28, 44)",
                }}
              >
                <div className="col-4 d-flex align-items-center">
                  <svg
                    width="22"
                    height="22"
                    viewBox="0 0 22 22"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    onClick={() => handleBack()}
                  >
                    <path
                      d="M1.18529 11.648L7.60196 18.0647C7.77484 18.2317 8.0064 18.3241 8.24674 18.322C8.48709 18.3199 8.717 18.2235 8.88696 18.0535C9.05692 17.8836 9.15332 17.6537 9.15541 17.4133C9.1575 17.173 9.0651 16.9414 8.89812 16.7685L4.04621 11.9166H20.1667C20.4098 11.9166 20.643 11.82 20.8149 11.6481C20.9868 11.4762 21.0834 11.2431 21.0834 11C21.0834 10.7568 20.9868 10.5237 20.8149 10.3518C20.643 10.1799 20.4098 10.0833 20.1667 10.0833H4.04621L8.89812 5.23137C8.98568 5.14681 9.05551 5.04566 9.10355 4.93382C9.15159 4.82198 9.17688 4.7017 9.17794 4.57999C9.179 4.45827 9.1558 4.33757 9.10971 4.22491C9.06362 4.11226 8.99555 4.00991 8.90949 3.92384C8.82342 3.83777 8.72107 3.7697 8.60842 3.72361C8.49576 3.67752 8.37506 3.65433 8.25334 3.65539C8.13163 3.65645 8.01134 3.68173 7.8995 3.72978C7.78767 3.77782 7.68652 3.84765 7.60196 3.9352L1.18529 10.3519C1.01344 10.5238 0.916904 10.7569 0.916904 11C0.916904 11.243 1.01344 11.4761 1.18529 11.648Z"
                      fill="white"
                    />
                  </svg>
                </div>
                <div
                  className="col-5 text-center"
                  style={{
                    background: "rgb(35, 28, 44)",
                  }}
                >
                  <p
                    className=" fw-bold mb-0"
                    style={{ fontSize: "15px", color: "white" }}
                  >
                    Host Center
                  </p>
                </div>
              </div>
              <div className="px-3 " style={{ marginTop: "65px" }}>
                <div
                  className="d-flex justify-content-between align-items-center mt-3"
                  style={{
                    borderRadius: "14px",
                    padding: "7px",
                    backgroundImage: `url(${gredientImage})`,
                    backgroundRepeat: "no-repeat",
                  }}
                >
                  <div className="bd-content">
                    <div className="d-flex align-items-center">
                      <div>
                        <img
                          src={admin?.image ? admin?.image : male}
                          style={{
                            height: "40px",
                            width: "40px",
                            objectFit: "cover",
                            borderRadius: "50%",
                          }}
                          alt=""
                        />
                      </div>
                      <div className="ms-3">
                        <p
                          className="mb-0  text-white"
                          style={{ fontSize: "18px", fontWeight: 700 }}
                        >
                          {admin?.name ? admin?.name : "-"}
                        </p>
                        <p
                          className="mb-0 "
                          style={{ fontSize: "13px", color: "#F3DADA" }}
                        >
                          UniqueId : {admin?.hostAgency?.uniqueId}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="date-picker">
                    <div className="date-picker d-flex justify-content-end ms-auto">
                      <DatePicker
                        selected={selectedDate}
                        dateFormat="yyyy/MM"
                        showMonthYearPicker
                        onChange={(date) => handleDateChange(date)}
                        customInput={<ExampleCustomInput />}
                        style={{ fontWeight: "bold" }}
                      />
                    </div>
                  </div>
                </div>
                <div
                  className="mt-2"
                >
                  <h2 className=""
                    style={{
                      fontWeight: 500,

                      color: "#FFFAFA",
                      fontSize: "20px",
                    }}>Today</h2>
                  <div
                    className="d-flex justify-content-between align-items-center px-3 py-2"
                    style={{
                      background: "#FBE5E5",
                      borderBottom: "1px solid #CCCCCC",
                      paddingLeft: "12px",
                      paddingRight: "12px",
                      borderRadius: "10px 10px 0px 0px",
                    }}
                  >
                    <div className="d-flex justify-content-around align-items-center">
                      <span
                        className=""
                        style={{
                          fontWeight: 500,
                          color: "#6D6C6C",
                          fontSize: "18px",
                        }}
                      >
                        Income
                      </span>
                    </div>

                    <div className="d-flex justify-content-between align-items-center">
                      <img src={Coin} />
                      <p
                        className="mt-3"
                        style={{
                          fontWeight: 500,
                          color: "#DA507C",
                          fontSize: "16px",
                        }}
                      >
                        {status?.todayEarning ? status?.todayEarning : 0}
                      </p>
                    </div>
                  </div>
                  <div
                    className="d-flex justify-content-between align-items-center px-3 py-2"
                    style={{
                      background: "#FBE5E5",
                      borderBottom: "1px solid #CCCCCC",
                      paddingLeft: "12px",
                      paddingRight: "12px",
                      borderRadius: "0px 0px 10px 10px",
                    }}
                  >
                    <div className="d-flex justify-content-around align-items-center">
                      <span
                        className=""
                        style={{
                          fontWeight: 500,
                          color: "#6D6C6C",
                          fontSize: "18px",
                          borderRadius: "0px 0px 14px 14px",
                        }}
                      >
                        Live Duration :
                      </span>
                    </div>

                    <div>
                      <p
                        className=" mt-2"
                        style={{
                          fontWeight: 500,
                          color: "#6D6C6C",
                          fontSize: "16px",
                        }}
                      >
                        {status?.liveDuration ? status?.liveDuration : 0}
                      </p>
                    </div>
                  </div>
                </div>

                <div
                  className="mt-4"
                >
                  <h2 className=" mt-2"
                    style={{
                      fontWeight: 500,
                      color: "#FFFAFA",
                      fontSize: "20px",
                    }}>This Month</h2>
                  <div
                    className="d-flex justify-content-between align-items-center px-3 py-2"
                    style={{
                      background: "#FBE5E5",
                      borderBottom: "1px solid #CCCCCC",
                      paddingLeft: "12px",
                      paddingRight: "12px",
                      borderRadius: "10px 10px 0px 0px",
                    }}
                  >
                    <div className="d-flex justify-content-around align-items-center">
                      <span
                        className=""
                        style={{
                          fontWeight: 500,
                          color: "#6D6C6C",
                          fontSize: "18px",
                        }}
                      >
                        Income
                      </span>
                    </div>

                    <div className="d-flex justify-content-between align-items-center">
                      <img src={Coin} />
                      <p
                        className="mt-3 "
                        style={{
                          fontWeight: 500,
                          color: "#DA507C",
                          fontSize: "16px",
                        }}
                      >
                        {monthlyStatus?.coins ? monthlyStatus?.coins : 0}
                      </p>
                    </div>
                  </div>
                  <div
                    className="d-flex justify-content-between align-items-center px-3 py-2"
                    style={{
                      background: "#FBE5E5",
                      borderBottom: "1px solid #CCCCCC",
                      paddingLeft: "12px",
                      paddingRight: "12px",
                    }}
                  >
                    <div className="d-flex justify-content-around align-items-center">
                      <span
                        className=""
                        style={{
                          fontWeight: 500,
                          color: "#6D6C6C",
                          fontSize: "18px",
                        }}
                      >
                        Live Duration :
                      </span>
                    </div>

                    <div>
                      <p
                        className=" mt-2"
                        style={{
                          fontWeight: 500,
                          color: "#6D6C6C",
                          fontSize: "16px",
                        }}
                      >
                        {monthlyStatus?.totalDuration ? monthlyStatus?.totalDuration : 0}
                      </p>
                    </div>
                  </div>
                  <div
                    className="d-flex justify-content-between align-items-center px-3 py-2"
                    style={{
                      background: "#FBE5E5",
                      borderBottom: "1px solid #CCCCCC",
                      paddingLeft: "12px",
                      paddingRight: "12px",
                      borderRadius: "0px 0px 14px 14px",
                    }}
                  >
                    <div className="d-flex justify-content-around align-items-center">
                      <span
                        className=" "
                        style={{
                          fontWeight: 500,
                          color: "#6D6C6C",
                          fontSize: "18px",
                        }}
                      >
                        Valid live days :
                      </span>
                    </div>
                    <div>
                      <p
                        className=" mt-2"
                        style={{
                          fontWeight: 500,
                          color: "#6D6C6C",
                          fontSize: "16px",
                        }}
                      >
                        {monthlyStatus?.validDays ? monthlyStatus?.validDays + " days" : 0 + " day"}
                      </p>
                    </div>
                  </div>

                </div>

                <div className="mt-3">
                  <div
                    className="d-flex align-items-center justify-content-between"
                    style={{
                      padding: "7px",
                      borderRadius: "0px 10px 10px 10px",
                      backgroundImage: `url(${gredientSecondImage})`,
                      backgroundRepeat: "no-repeat",
                    }}
                  >
                    <div>
                      <div className="d-flex align-items-center">
                        <img
                          src={
                            !admin?.hostAgency?.image?.includes("\\")
                              ? admin?.hostAgency?.image
                              : male
                          }
                          style={{
                            height: "28px",
                            width: "28px",
                            objectFit: "cover",
                            borderRadius: "50%",
                          }}
                          alt=""
                        />
                        <h6 className="font-medium ms-2 mb-0 text-white" style={{ fontSize: "15px" }}>
                          {admin?.hostAgency?.name ? admin?.hostAgency?.name : "-"}Bd Agency
                        </h6>
                      </div>

                      <div className="d-flex align-items-center mt-2">
                        <h6 className="font-medium mb-0 text-white">
                          ID:{" "}
                          {admin?.hostAgency?.uniqueId ? admin?.hostAgency?.uniqueId : "-"}202230
                        </h6>
                        <button
                          className=""
                          style={{
                            border: "none",
                            backgroundColor: "transparent",
                          }}
                          onClick={() => handleCopy(admin?.hostAgency?.uniqueId)}
                        >

                          <img src={Copy} alt="" height={15} width={15}
                            style={{ color: "black", }}
                          />
                        </button>
                      </div>
                    </div>
                    <div>
                      <h6 className="font-medium mb-0 text-white">My Agency</h6>
                    </div>
                  </div>
                </div>


                <div className="d-flex justify-content-around mt-3 mb-3">
                  <div className="ms-1">
                    <h4
                      className={`${type === 2 ? "text-white fw-bold" : "text-gray"
                        } mb-0`}
                      style={{ fontSize: "12px", cursor: "pointer" }}
                      onClick={() => setType(2)}
                    >
                      Payment History
                      <div className="d-flex justify-content-center align-items-center">
                        {type === 2 && (
                          <hr className="text-white d-flex justify-content-center" />
                        )}
                      </div>
                    </h4>
                  </div>
                  <div className="ms-3">
                    <h4
                      className={`${type === 1 ? "text-white fw-bold" : "text-gray"
                        } mb-0`}
                      style={{ fontSize: "12px", cursor: "pointer" }}
                      onClick={() => setType(1)}
                    >
                      Daily Income
                      <div className="d-flex justify-content-center align-items-center">
                        {type === 1 && (
                          <hr className="text-white d-flex justify-content-center" />
                        )}
                      </div>
                    </h4>
                  </div>

                  <div className="ms-3">
                    <h4
                      className={`${type === 3 ? "text-white fw-bold" : "text-gray"
                        } mb-0`}
                      style={{ fontSize: "12px", cursor: "pointer" }}
                      onClick={() => setType(3)}
                    >
                      Video Call
                      <div className="d-flex justify-content-center align-items-center">
                        {type === 3 && (
                          <hr className="text-white d-flex justify-content-center" />
                        )}
                      </div>
                    </h4>
                  </div>
                </div>
                {type === 2 && (
                  <div className="d-flex justify-content-end">
                    <button className="p-2" style={{ backgroundColor: "#9859FF", color: "white", borderRadius: "6px", border: "none" }} onClick={handleAddAccountClick}>Add Account</button>
                  </div>
                )}
                {
                  isModalOpen && (
                    <div className="modal-overlay m-3">
                      <div className="modal-content">
                        <div className="modal-header">
                          <span className="modal-title">Payout Details</span>
                          <button className="close-button" onClick={closeModal}>×</button>
                        </div>
                        <form>
                          <div className="form-group">
                            <label htmlFor="accountName">Account Name</label>
                            <input
                              type="text"
                              id="accountName"
                              name="accountName"
                              placeholder="Enter account name"
                              required
                              value={values?.accountName}
                              onChange={(e) => {
                                handleOnChange(e)
                              }}
                            />
                          </div>
                          <div className="form-group">
                            <label htmlFor="paymentMethod">Payment Method</label>
                            <input
                              type="text"
                              id="paymentMethod"
                              name="paymentMethod"
                              placeholder="Enter payment method"
                              required
                              value={values?.paymentMethod}
                              onChange={(e) => {
                                handleOnChange(e)
                              }}
                            />
                          </div>
                          <div className="form-group">
                            <label htmlFor="accountNumber">Account Number</label>
                            <input
                              type="text"
                              id="accountNumber"
                              name="accountNumber"
                              placeholder="Enter account number"
                              required
                              value={values?.accountNumber}
                              onChange={(e) => {
                                handleOnChange(e)
                              }}
                            />
                          </div>
                        </form>
                        <button className="confirm-button" onClick={() => handleEditDetails()}>Confirm Binding</button>
                      </div>
                    </div>
                  )
                }
                {type === 1 && (
                  <>
                    <DailyIncome />
                  </>
                )}
                {type === 2 && (
                  <>
                    <HostSettlement />
                  </>
                )}

                {type === 3 && (
                  <>
                    <VideoCall />
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Admin;

// add country and address field in tikito
// add design in tikito live
// add and show ban details
// 